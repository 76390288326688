





















































import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import NormDetails from '@/components/norms/NormDetails.vue'
import removeMd from 'remove-markdown'
import variables from '@/shared/variables'

export default defineComponent({
    name: 'ThreatOperations',
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
    },
    components: {
        NormDetails,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labels = ref({})
        const copyOfEntityDetails = ref({})
        const TabviewCmp = ref(null)
        const nameInitals = ref('')

        // go to record (previous/ next)
        const goToRecord = (params: { typeOfButton: string; no: string }) => {
            emit('go-to-record', params)
        }

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.created_by && props.entityDetails.created_by.firstName) +
                ' ' +
                (props.entityDetails.created_by && props.entityDetails.created_by.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            labels,
            copyOfEntityDetails,
            goToRecord,
            TabviewCmp,
            removeMd,
            nameInitals,
            variables,
        }
    },
})
