


































































































import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import query from '@/shared/queries/normQueries'
import variables from '@/shared/variables'
import MSRNormOperations from '@/components/msr-norms/MSRNormOperations.vue'
import { UserRole } from '@/shared/enum/general-enum'
import normsData from '@/assets/data/norms-data.json'
import { NormDTO } from '@/dto/backend-response/normsDTO'
import msrData from '@/assets/data/msr-data.json'

export default defineComponent({
    components: { Table, MSRNormOperations },
    props: {
        idfilter: {
            type: Number,
        },
    },
    setup() {
        const normList = ref([])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { menuOpen } = useState(['menuOpen'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const displayCreateNorm = ref(false)
        const visiblenormDetails = ref(false)
        const normDetails = ref(null)
        const visibleLeft = ref(false)
        const tableCmp = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const normFilters = msrData.MSR_NORM_FILTERS
        // get current organization id
        const totalPages = ref(0)

        // set table headers
        const setTableHeaders = (language: string) => {
            tableHeaders.value = [
                {
                    header: t('NORMS_TABLE_COLUMN_NO', language),
                    sort: true,
                    fieldName: 'no',
                    style: 'min-width: 150px;',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_NAME', language),
                    sort: true,
                    fieldName: 'name',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'description',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_VERSION', language),
                    sort: true,
                    fieldName: 'version',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_PUBLICATION_DATE', language),
                    sort: true,
                    fieldName: 'publicationDate',
                    type: 'Date',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_TYPE', language),
                    sort: true,
                    fieldName: 'type',
                },
            ]
        }

        // updating normList array with additional fields and setting description content
        const toTableFormat = (tempResultData: Array<NormDTO>) => {
            tempResultData.map((norm, index) => {
                let normCopy = {
                    no: 0,
                    type: null,
                    ownerUserName: null,
                }
                normCopy = Object.create(norm) // creating a copy of norm object
                normCopy.no = utils.padLeft('000', index + 1) // creating no
                normCopy.type = t(
                    utils.keyToName(normsData.NORM_TYPE, norm.normType),
                    language.value
                )
                normList.value.push(normCopy)
            })
            filteredRecordCount.value = normList.value.length
        }

        // get all norms
        const getNorms = async (params: { pageNum: number }) => {
            loading.value = true
            const pageNum = params.pageNum
            const getNormsQuery = `
                query{
                    msrNorms(perPage: ${variables.LIST_ITEMS_PER_PAGE}, pageNum: ${pageNum}, filter: {isSelectedNorms:true}){
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items{
                        ${query.NORMS}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getNormsQuery)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving msr norms')
            }
            if (result) {
                const norms = result.data.msrNorms.items
                norms.sort((a, b) => (a.id > b.id ? 1 : -1))
                totalPages.value = result.data.msrNorms.pageInfo.totalPages
                filteredRecordCount.value = result.data.msrNorms.pageInfo.totalCount
                toTableFormat(norms)
            }
        }

        // get norm by id
        const getNorm = async (id: number) => {
            normDetails.value = null
            displayCreateNorm.value = false
            const normQuery = `
                query {
                    msrNorm(id: ${id}) {
                        ${query.NORM_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(normQuery)
            } catch {
                throw Error('Error while retrieving msr norm details')
            }
            if (result) {
                normDetails.value = result.data.msrNorm
                normDetails.value.no = normList.value.find(
                    (val) => val.id === normDetails.value.id
                ).no
                normDetails.value.lastRecordNo =
                    tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
                normDetails.value.firstRecordNo =
                    tableCmp.value && tableCmp.value.copyOfTableData[0].no
            }
        }

        // go to norm record
        const goToRecord = async (params: { typeOfButton: string; no: string }) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `normList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.typeOfButton,
                    params.no,
                    tableCmp.value
                )
                // use the id of the record to get next/previous details
                await getNorm(record.id)
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        //  display therat details sidebar
        const openNormDetailsPopup = async (id: number) => {
            visiblenormDetails.value = true
            await getNorm(id)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count: number) => {
            filterCount.value = count
        }

        // set searched records count
        const searchCount = (count: number) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }
        // update table records count
        const updateRecordCount = (recordsCount: number) => {
            filteredRecordCount.value = recordsCount
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close view details entity if it's open
                    if (visiblenormDetails.value) {
                        visiblenormDetails.value = false
                    }
                    // close create new entity if it's open
                    if (displayCreateNorm.value) {
                        displayCreateNorm.value = false
                    }
                }
            })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            (newValue: string) => {
                setTableHeaders(newValue)
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            closeSidebarEsc()
            getNorms({ pageNum: 1 })
        })

        return {
            normList,
            getNorm,
            t,
            language,
            tableHeaders,
            loading,
            infomationOp,
            toggleInformation,
            displayCreateNorm,
            getNorms,
            role,
            variables,
            openNormDetailsPopup,
            visiblenormDetails,
            normDetails,
            goToRecord,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            tableCmp,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            normFilters,
            UserRole,
            totalPages,
        }
    },
})
